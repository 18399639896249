* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-red: #AF2A23;
  --muted-red: #c3312c;
  --light-red: #fc544f;
  --dark-red: #73140F;
  --grey: #868686;
  --light-grey: #EBEBEB;
  --white: #ffffff;
  --black: #000000;
  --light-black: #2F2F2F;
  --dark-grey: #202020;
}
/* 
.App .bg {
  background-image: url('./asset/background.jpg');
  background-size: cover;
  background-position: 100% 15%;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: -2;
} */

.App {
  position: relative;
}

.App {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.App.dark {
  filter: brightness(0.8);
}

video.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position:  50%; 
  z-index: -1;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

video.background-video.status {
  z-index: 0;
}

video.fade-out {
  opacity: 0;
}

video.fade-in {
  opacity: 1;
}

.hidden-video {
  display: none;
}

/* .bg-vid {
  position: relative;
} */

.background-gif {
  position: absolute;
  top: 62%;
  left: 29.79%;
  width: 3.29%;
  height: 6.5%;
  object-fit: fill; 
  object-position: 0% 15%;
  z-index: -1;
}

.status {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.status.search {
  width: 100%;
  top: -10%;
  left: 50%;
  transform: translateX(-50%);
  
}

.gif {
  width: 100%;
  height: 100%;
}

.hidden-gif {
  display: none;
}
