.demo #particles {
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0;
}

.demo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.demo .chatbox {
  margin-right: 1rem;
  margin-bottom: 1.5%;
  max-width: 30vw;
  width: 450px;
  height: 85vh;
  background-color: var(--white);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  box-shadow: -4px -6px 25px rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.demo .header {
  background-image: url('../../asset/Header\ \(3\).png');
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
  position: relative;
  padding: 8%;
}

.demo .header img {
  width: 60%;
}

.demo .header .toggle-lang {
  width: 80px;
  height: 30px;
  position:absolute;
  display: flex;
  gap: 0.4rem;
  right: 0.5rem;
  top: 1.25rem;
  color: var(--white);
  font-weight: 300;
  cursor: pointer;
  font-size: 18px;
}

.demo .header .toggle-lang span.selected {
  font-weight: 700;
}

.demo .fixed {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  margin-bottom: 0.5rem;
}

.demo .fixed .timestamp {
  align-self: center; 
}

.demo .fixed .toggle-tts {
  position: absolute;
  right: 9%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 0.3rem;
}
 
.demo .fixed .toggle-tts img {
  width: 18px;
  height: 22px;
}

.demo .fixed .toggle-tts label.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 22px;
  border-radius: 100px;
  background: linear-gradient(180deg, rgba(208,208,208,1) 28%, rgba(235,235,235,1) 100%);  display: inline-block; 
  cursor: pointer;
  position: relative;
  z-index: 1000;
  transition: 0.3s;
}

.demo .fixed .toggle-tts label.button::before {
  position: absolute;
  content: '';
  background-color: var(--white);
  width: 18px;
  height: 18px;
  border-radius: 100px;
  margin: 2px;
  box-shadow: 3px 0px 8px rgba(0, 0, 0, 0.3);
  transition: 0.3s;
}

.demo .fixed .toggle-tts input[type="checkbox"]:checked + .button {
  background: linear-gradient(180deg, rgba(175,42,35,1) 28%, rgba(195,49,44,1) 100%);
}

.demo .fixed .toggle-tts input[type="checkbox"]:checked + .button::before {
  transform: translateX(28px);
  box-shadow: -3px 0px 8px rgba(0, 0, 0, 0.3);
}

.demo .fixed .toggle-tts input {
  display: none;
}

.demo .conversation {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  position: relative;
  padding: 0% 5%;
  padding-bottom: 25px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.demo .conversation::-webkit-scrollbar {
  width: 12px;
  background-color: transparent; 
}

.demo .conversation::-webkit-scrollbar-thumb {
  background-color: var(--light-grey); 
  border-radius: 10px;
}

.demo .conversation::-webkit-scrollbar-thumb {
  visibility: hidden;
}

.demo .conversation:hover::-webkit-scrollbar-thumb {
  visibility: visible;
  cursor: pointer; 
}

.demo .timestamp {
  color: var(--grey);
}

.demo .suggestion {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-left: 5px;
}

.demo .suggestion>p {
  color: var(--grey);
}

.demo .suggestion>div {
  width: 450px;
  box-sizing: border-box;
  overflow-x: auto; 
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  position: relative;
  padding-bottom: 10px;
}

.demo .suggestion>div::-webkit-scrollbar {
  height: 10px; 
  background-color: transparent; 
}

.demo .suggestion>div::-webkit-scrollbar-thumb {
  background-color: var(--light-grey); 
  background-clip: content-box;
  border-radius: 5px;
}

.demo .suggestion>div::-webkit-scrollbar-thumb {
  visibility: hidden;
}

.demo .suggestion>div:hover::-webkit-scrollbar-thumb {
  visibility: visible;
  cursor: pointer; 
}

.demo .suggestion .tag {
  color: var(--black);
  padding: 7px 10px;
  border-radius: 25px;
  border: 2px solid var(--primary-red);
  text-align: center;
  text-wrap: nowrap;
  cursor: pointer;
}

.demo .suggestion .tag.inactive {
  cursor:  not-allowed;
}

.demo .suggestion .tag:hover {
  box-shadow: 0px 2px 8px rgba(175, 42, 35, 0.2); 
}

.demo .suggestion .tag.selected {
  border: 2px solid var(--primary-red);
  box-shadow: 0px 2px 8px rgba(175, 42, 35, 0.2); 
  filter: brightness(0.8);
}

.demo .user,
.demo .bot {
  padding: 15px 20px;
  border-radius: 20px;
}

.demo .bot::before {
  position: absolute;
  left: -50px;
  top: 0;
  content: "";
  background-image: url('../../asset/bot_logo.png');
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  display: block;
}

.demo .user {
  margin-right: 50px;
  color: var(--white);
  background-color: var(--muted-red);
  position: relative;
  border-top-right-radius: 0px;
  margin-left: auto; 
}

.demo .user::before {
  position: absolute;
  right: -50px;
  top: 0;
  content: "";
  background-image: url('../../asset/User\ Logo.png');
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  display: block;
}

.demo .bot {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-left: 50px;
  color: var(--black);
  background-color: var(--light-grey);
  position: relative;
  border-top-left-radius: 0px;
  margin-right: auto; 
}

.demo .bot::before {
  position: absolute;
  right: -50px;
  top: 0;
  content: "";
  background-image: url('../../asset/bot_logo.png');
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  display: block;
}

.demo .bot .tts {
  width: 100px;
}


.demo a.pdf-link {
  color: var(--muted-red);
  font-style: italic;
}

.demo .user-input {
  background-color: var(--white);
  box-shadow: 0px -4px 25px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  gap: 15px;
  width: 100%;
}

.demo .user-input>img {
  width: 28px;
  height: 28px;
  opacity: 0.6;
  cursor: pointer;
}

.demo .user-input input {
  width: 100%;
}

.demo .user-input button {
  cursor: pointer;
}

.demo .user-input input,
.demo .user-input button {
  border: none;
  background-color: transparent;
}

.demo .user-input input:focus {
  outline: none;
}

.demo .user-input .send-button img {
  width: 30px;
  height: 30px;
}

.demo .user-input button:hover {
  filter: brightness(0.8);
}

.demo .user-input button.inactive {
  cursor: not-allowed;
  filter: grayscale(100%)
}

/* DARK MODE */
.demo .chatbox.dark {
  background-color: var(--light-black);
}

.demo .chatbox.dark .bot {
  background-color: var(--dark-grey);
  color: var(--white);
}

.demo .chatbox.dark .suggestion>div::-webkit-scrollbar-thumb {
  background-color: var(--grey);
}

.demo .chatbox.dark .suggestion .tag {
  color: var(--white);
}

.demo .chatbox.dark .conversation::-webkit-scrollbar-thumb{
  background-color: var(--grey);
}

.demo .chatbox.dark .user-input {
  background-color: var(--dark-grey);
}

.demo .chatbox.dark .user-input input {
  color: var(--white);
}

.demo .chatbox.dark .user-input button:hover {
  filter: brightness(1.3);
}

.demo .chatbox.dark .user-input>img {
  filter: invert(1);
}

.demo .chatbox.dark .toggle-tts {
  filter: brightness(1);
}

.demo a.pdf-link.dark-mode {
  color: var(--light-red)
}

.demo .chatbox.dark .bot>button img:first-child{
  filter: invert(1);
}

.demo .chatbox.dark .bot>button  p {
  color: var(--white);
}

/* DEMO STYLING */

.demo .billbot {
  left: 0;
  bottom: 0;
  position: fixed;
  z-index: 999;
}

.demo .billbot img {
  width: 45vw;
}

.demo .video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.demo .background-video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.demo .demo-video {
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: 999;
}
/* RESPONSIVE */
@media screen and (max-width: 600px) {
  .demo {
    display: flex;
    justify-content: center;
    padding: 5%;
  }

  .demo .chatbox {
    margin: 0;
  }

  .demo .suggestion>div {
    width: 300px;
  }
}

@media screen and (max-width: 450px) {
  .demo .header {
    background-size: contain;
    padding: 5%;
  }

  .demo .fixed {
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-content: center;
    gap: 0.75rem;
  }

  .demo .fixed .toggle-tts {
    position: relative;
    right: 0px;
  }
}

@media screen and (max-height: 649px) {
  .demo .header {
    height: fit-content;
    background-position: 100% 100%;
  }
}

/* ANIMATION */
/* ----------------------------------------------
 * Generated by Animista on 2024-4-23 14:26:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-right
 * ----------------------------------------
 */

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
            opacity: 0;
  }
  50% {
    opacity: 0.25;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
            opacity: 0.7;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
